/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import { Heading, Text, Link, Typography } from '@fiverr-private/typography';
import { useParentPropsOverrideContext } from '@fiverr-private/ui_utils';
import withClick from '../helpers/withClick';
import { componentNameInput, eventEnrichmentInput, clickEventNameInput } from '../helpers/inputs';
import HeadingConfig from '../../../../docs/builderTypographyComponents/Heading.json';
import TypographyConfig from '../../../../docs/builderTypographyComponents/Typography.json';
import TextConfig from '../../../../docs/builderTypographyComponents/Text.json';
import LinkConfig from '../../../../docs/builderTypographyComponents/Link.json';

import * as classes from './styles.ve.css';

const getAsProp = (as, text, htmlText) => {
    if (!as) {
        return {};
    }

    if (as === 'p' && !text && htmlText) {
        return { as: 'span' };
    }

    return { as };
};

const asTypography =
    (WrappedComponent) =>
    ({ text, htmlText, as, renderChildren = false, embed = false, children, ...props }) => {
        const textContent =
            text ||
            (htmlText ? <div className={classes.richtext} dangerouslySetInnerHTML={{ __html: htmlText }} /> : null);
        const renderContent = textContent || (renderChildren ? children : null);

        if (embed) {
            return renderContent;
        }

        return (
            <WrappedComponent {...props} {...getAsProp(as, text, htmlText)}>
                {renderContent}
            </WrappedComponent>
        );
    };

const asLink = (WrappedComponent) => (props) => {
    const context = useParentPropsOverrideContext();
    const ClickTypography = asTypography(withClick(WrappedComponent));
    return <ClickTypography {...props} {...context} renderChildren={true} />;
};

const TYPOGRAPHY_COMPONENTS_CONFIG = {
    components: [
        { element: Typography, componentConfig: TypographyConfig, hoc: asTypography },
        { element: Heading, componentConfig: HeadingConfig, hoc: asTypography },
        {
            element: Text,
            componentConfig: TextConfig,
            hoc: asTypography,
            manualInputs: [
                {
                    name: 'embed',
                    type: 'boolean',
                    required: false,
                    defaultValue: false,
                    showIf: () => false,
                },
            ],
        },
        {
            element: Link,
            componentConfig: LinkConfig,
            hoc: asLink,
            manualInputs: [clickEventNameInput, componentNameInput, eventEnrichmentInput],
        },
    ],
    category: 'Typography',
    manualInputs: [
        {
            name: 'text',
            type: 'longText',
            required: false,
            defaultValue: 'This is text example',
            localized: true,
        },
        {
            name: 'htmlText',
            type: 'richText',
            required: false,
            localized: true,
        },
    ],
    manualOptions: {
        defaultChildren: undefined,
    },
};

export default TYPOGRAPHY_COMPONENTS_CONFIG;
